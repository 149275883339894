<template>
  <div class="default-navigation">
    <ul class="dh-container">
      <li v-for="(item,index) in menu" :key="index">
        <span @click="navTo(item.link)">{{ item.name }}</span>
        <template v-if="item.children">
          <div class="subMenu">
            <div v-for="(sub,i) in item.children" :key="`${index} - ${i}`" class="subMenu-item" @click="navTo(sub.link)">
              {{ sub.name }}
            </div>
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>
<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 9:46
 * @version 1.0
 * @description 通用橙色导航条
 */
export default {
  name: 'DefaultNavigation',
  props: {
    menu: {
      type: Array,
      default: () => {
        return [{
          name: 'None1',
          link: '#',
          children: [
            {
              name: 'First',
              link: '#'
            },
            {
              name: 'Second',
              link: '#'
          }]
        },
        {
          name: 'two',
          link: '#'
        }]
      }
    }
  },
  methods: {
    navTo(url) {
      console.log(url, 454454545454)
      this.$router.push(url)
    }
  },
}
</script>

<style lang="scss" scoped>
.dh-container{
  max-width: 1500px;
  margin: 0 auto;
}
.default-navigation {
  height: 46px;
  line-height: 46px;
  background: #0962ea;

  ul {
    padding: 0;
    li {
      list-style: none;
      height: 100%;
      position: relative;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      span{
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        width: 100%;
        display: block;
        height: 100%;
        padding: 0 22px;
        font-family: "PingFang SC",serif;

      }
      &:hover{
        background: #0451c5;
        .subMenu {
          display: block;
        }
      }
      z-index: 8;
    }
  }
}
.subMenu{
  position: absolute;
  z-index: 11;
  display: none;
  background: rgb(230,136,47);
  left: 0;
  color: #fff;
  min-width: 100%;
  .subMenu-item{
    font-family: "PingFang SC",serif;

  }
}
</style>
